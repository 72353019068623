import { FlatList, ActivityIndicator, ScrollView } from "react-native";
import { spacing_l, spacing_m, white_smoke, spacing_m_vw, spacing_xs } from "../../StyleHelpers";
import { useEffect, useMemo, useState } from "react";
import { Channel } from "../../models/Channel";
import { Controller } from "../../models/Controller";
import styled, { css } from "styled-components/native";
import { AsyncStorageKeys, ControllerEnum, Pages, ProfileName, direction } from "../../Types";
import ScreenManagerContainer from "../../components/Layout/ScreenManagerContainer";
import ScreenManagerChannelButtons from "./ScreenManagerChannelButtons";
import Separator from "../../components/Separator";
import IconPause from "../../assets/ControllerIcon/Pause.svg";
import IconPlay from "../../assets/ControllerIcon/Play.svg";
import TouchableButton from "../../components/TouchableButton";
import ComponentThemeType from "../../models/ComponentTheme";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import Image from "../../components/Image/Image";
import ImgProgressBar from "../../components/Loaders/ImgProgressBar";
import ControllerContainer from "../../components/Layout/ControllerContainer";
import { generatePlayContent, getDeviceInfo, getGroupId, getTranslatedDeviceName, globalAny, sortDevices, streamProgress } from "../../utils/Utils";
import useMqttStore from "../../store/useMqtt.store";
import MqttSenderService from "../../services/mqtt/MqttSenderService";
import { v4 as uuidv4 } from "uuid";
import { getProgressVideo } from "../../services/progressVideoService";
import { useNavigation } from "@react-navigation/native";
import useGenericContentStore from "../../store/genericContent.store";
import { routeNewSettings } from "../../Routes";
import { MqttPlayContent } from "../../models/MqttModel";

//#region Props
interface Props {
	addMarginLeft?: any;
	isSync?: boolean;
	location?: any;
	wheelPosition?: any;
}

interface ModelMappedScreensDisplay {
	frontScreen: Channel[];
	carScreens: Channel[];
	otherScreens: Channel[];
}

interface IMediaCommandProps {
	action: string;
	productId: any;
	screenId: any;
	title: string;
	bgImage: string;
	reserveImage: string;
	linkDevices: any;
	linkProduct: any;
	streamCurrentPosition?: any;
	groupSync?: any;
	streamProgress?: number;
	vamId?: any;
	redirectTo?: string;
	location?: string;
}

interface LoaderProps {
	width?: any;
	height?: any;
	marginBottomLarge?: boolean;
}

interface StyledProps {
	wheelPosition: any;
}

//#endregion

//#region Styled Components
const StyledParentContainer = styled.View`
	height: 100vh;
`;

const StyledMainContainer = styled.View<Props>`
	height: 100vh;
	padding-bottom: 10%;
	z-index: -2;

	${(props) =>
		!props.addMarginLeft &&
		css`
			margin-left: ${spacing_l};
		`}

	${(props) =>
		props.addMarginLeft &&
		css`
			margin-left: 92;
			margin-right: 51;
		`}
`;

const StyledCard = styled.View`
	width: 100%;
	padding: 0 ${spacing_l} ${spacing_l} 0;
`;

const StyledMargin = styled.View`
	margin: 0 ${spacing_l} 0 0;
`;

const StyledNoDevices = styled.View`
	margin: 63px 0 10% 20px;
`;
const StyledWithDevices = styled.View`
	margin: 0px 0 10% 0px;
`;

const StyledContainer = styled.View<any>`
	${(props) =>
		props.wheelPosition === "right" &&
		css`
			margin: 0 120px 0 30px;
		`}

	${(props) =>
		props.wheelPosition !== "right" &&
		css`
			margin: 0 30px 0 0;
		`}

	${(props) =>
		props.addMarginLeft &&
		css`
			padding-right: 51;
		`}
`;

const StyledText = styled.View<StyledProps>`
	height: 100%;
	flex-direction: row;
	align-items: center;

	${(props) =>
		props.wheelPosition !== "right" &&
		css`
			justify-content: space-between;
		`}

	${(props) =>
		props.wheelPosition === "right" &&
		css`
			justify-content: start;
		`}
`;

const StyledTextContainer = styled.View<any>`
	height: 75px;

	${(props) =>
		props.isSync &&
		css`
			justify-content: center;
			align-items: center;
		`}

	${(props) =>
		props.location !== Pages.assignScreen &&
		props.wheelPosition !== "right" &&
		css`
			margin-left: 145px;
		`}

	${(props) =>
		props.location !== Pages.assignScreen &&
		props.wheelPosition === "right" &&
		css`
			margin-left: 0px;
			padding-left: 0px;
		`}
`;

const StyledController = styled.View<any>`
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 14.02vw;
`;

const StyledImage = styled.View`
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
`;

const StyledButtonText = styled.Text`
	font-size: 23px;
	margin-left: -3px;
`;

const StyledOtherDevicesHeader = styled.Text`
	color: ${white_smoke};
	font-size: 30;
`;

const StyledOtherDevicesBody = styled.Text`
	color: ${white_smoke};
	font-size: 25;
	opacity: 0.5;
`;

const StyledControllerText = styled.Text<any>`
	color: white;
	font-size: 25;

	${(props) =>
		props.direction &&
		css`
			text-align: center;
			width: 100%;
		`}
`;

const StyledEmptySpace = styled.View`
	width: 11.45vh;
	height: 100%;
	margin-right: 20px;
`;

const StyledControllerContainer = styled.View`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

const StyledLoading = styled.View<Props>`
	border-radius: 16px;
	overflow: hidden;
	z-index: -1;

	${(props) =>
		props.addMarginLeft &&
		css`
			margin: ${spacing_l} 0 0 0;
			width: 98.4%;
			height: 194px;
		`}
	${(props) =>
		!props.addMarginLeft &&
		css`
			margin: ${spacing_l} 0 0 0;
			width: 98.4%;
			height: 194px;
		`}
`;

const StyledSync = styled.View<any>`
	display: flex;
	width: 100%;

	${(props) =>
		props.wheelPosition !== "right" &&
		css`
			flex-direction: row;
			margin-left: 147px;
		`}

	${(props) =>
		props.wheelPosition === "right" &&
		css`
			flex-direction: row-reverse;
			margin-left: 0;
			padding-right: 30px;
		`}
`;

const StyledSyncBody = styled.View`
	justify-content: center;
`;

const StyledCancelContainer = styled.View`
	width: 172px;
`;

const StyledAddScreenContainer = styled.View`
	width: 210px;
`;

const StyledCancelButton = styled.View<any>`
	${(props) =>
		(props.redirectTo === direction.player || props.redirectTo === "main-player") &&
		css`
			margin-right: 120px;
		`}

	width: 14.02vw;
`;

const StyledControllerButton = styled.View<any>`
	${(props) =>
		props.location !== Pages.assignScreen &&
		css`
			margin-left: 145px;
		`}

	${(props) =>
		props.location === Pages.assignScreen &&
		css`
			margin-left: 110px;
		`}
	display: flex;
	flex-direction: row;
`;

const StyledOtherDevicesContainer = styled.View`
	margin-top: 20px;
`;

const StyledOtherDevicesLoader = styled.View<LoaderProps>`
	overflow: hidden;
	width: ${(props) => props.width};
	height: ${(props) => props.height};

	${(props) =>
		props.marginBottomLarge &&
		css`
			border-radius: 12px;
			margin-bottom: ${spacing_m};
		`}
	${(props) =>
		!props.marginBottomLarge &&
		css`
			border-radius: 8px;
			margin-bottom: ${spacing_xs};
		`}
`;

const StyledRightTextContainer = styled.View<any>`
	${(props) =>
		props.assignScreen !== Pages.assignScreen &&
		css`
			display: flex;
			flex-direction: row-reverse;
			padding-right: 230px;
			width: 100%;
		`}

	${(props) =>
		props.syncRibbon &&
		css`
			display: flex;
			flex-direction: row;
			justify-content: center;
			padding-left: 230px;
		`}
`;
//#endregion

const ScreenManager = (props: any) => {
	const { redirectTo, location, vamId, parentProductId } = props.route.params;
	const [hideController, setHideController] = useState(false);
	const [syncRibbon, setSyncRibbon] = useState(false);
	const [showSpinner, setShowSpinner] = useState(false);
	const [controllerText, setControllerText] = useState(props.controllerText);
	const [channelData, setChannelData] = useState<Channel[]>([]);
	const [showController, setShowController] = useState(false);
	const [sync, setSync] = useState(false);
	const [controllerData, setControllerData] = useState<Controller[]>(props.controller);
	const [delay, setDelay] = useState(false);
	const mqttDevices = useMqttStore((state: any) => state.mqttDevices);
	const deviceInfo = useMqttStore((state: any) => state.deviceInfo);
	const syncBody = globalAny.language.sync_body;
	const defaultAction = globalAny.language.browsing;
	const mqttSender: MqttSenderService = useMqttStore((state: any) => state.mqttSender);
	const wheelPosition = localStorage.getItem(AsyncStorageKeys.wheelPosition);

	const navigation = useNavigation();
	const setSelectedTabs = useGenericContentStore((state: any) => state.setSelectedTabs);

	const getMovieData = (movieDetails: any) => {
		const contentId = movieDetails?.contentId;
		const vamId = movieDetails?.vamId;

		if (vamId) {
			const vamData: any = localStorage.getItem(`vamdetails_${vamId}`);

			if (vamData) {
				return JSON.parse(vamData);
			}
		}

		if (contentId) {
			const movieData: any = localStorage.getItem(`moviedetails_${contentId}`);

			if (movieData) {
				return JSON.parse(movieData);
			}
		}

		return {
			title: globalAny.language.online,
			image: "",
		};
	};

	/**
	 * Function that check the channel update
	 * @param updatedChannel
	 * @returns
	 */
	const checkController = (updatedChannel: any) => {
		if (props.location === Pages.assignScreen) return;
		const channel = getSelectedChannel(updatedChannel);
		const hasSelected = channel.filter((items: any) => items.selected);
		const hasProductId = channel.filter((items: any) => items.productId);
		const [firstSelected] = channel.filter((items: any) => items.firstSelected);

		if (hasProductId.length >= 1 && hasSelected.length >= 1) {
			const updateController = controllerData.map((controls: Controller) => {
				if (controls?.text !== ControllerEnum.Sync && hasSelected.length <= 1) {
					return {
						...controls,
						disabled: false,
					};
				}

				if (hasSelected.length >= 2) {
					return { ...controls, disabled: false };
				}

				return controls;
			});

			const updateTextController = updateController.map((controls: Controller) => {
				if (firstSelected?.actionText === "Paused" && controls?.text === ControllerEnum.Pause) {
					return {
						...controls,
						text: ControllerEnum.Resume,
					};
				}

				if (firstSelected?.actionText === "Playing" && controls?.text === ControllerEnum.Resume) {
					return {
						...controls,
						text: ControllerEnum.Pause,
					};
				}

				return controls;
			});

			setControllerData(updateTextController);
		}

		if (hasProductId.length <= 0 && hasSelected.length >= 1) {
			const updateController = controllerData.map((controls: Controller) => {
				return { ...controls, disabled: true };
			});
			setControllerData(updateController);
		}
	};

	//#region Function
	const convertToChannel = (data: any) => {
		const channel: Channel[] = data.map((device: any) => {
			const movieDetails = device?.movieDetails?.body;
			const selectedChannel = onSelectChannel(device?.deviceId);
			const firstSelectedChannel = onFirstSelectChannel(device?.deviceId);
			const actionText = checkActionText(movieDetails?.state);
			const otherDevices = checkOtherDevices(device?.screenName);
			const groupSync = getGroupId(movieDetails?.playSessionId, device?.deviceId) ?? 0;
			const movieData = getMovieData(movieDetails);

			const channelFormat: Channel = {
				id: device?.deviceId,
				screenId: device?.screenId,
				productId: movieDetails?.contentId || null,
				vamId: movieDetails?.vamId || null,
				headerText: device?.screenName,
				screenName: device?.screenName,
				actionText: actionText,
				title: movieData.title,
				selected: selectedChannel ?? false,
				bgImage: movieData.image,
				otherDevices: otherDevices,
				streamProgress: movieDetails?.position || 0,
				duration: movieDetails?.duration || 0,
				playSessionId: movieDetails?.playSessionId,
				link: groupSync !== 0,
				reserveImage: "",
				buffering: false,
				groupSync: groupSync || "",
				firstSelected: firstSelectedChannel ?? false,
			};

			return channelFormat;
		});

		checkController(channel);

		return channel;
	};

	/**
	 * Handling the controller state for pause and resume
	 * @param state
	 */
	const updateControllerState = (channelData: any, controllerData: any) => {
		if (props.location === Pages.assignScreen) return;
		const selected = getSelectedChannel(channelData);
		const [selectedChannel] = selected;

		if (selected.length === 0) return;

		const isState = selectedChannel.actionText === "Paused";

		const updateController = controllerData.map((controls: any, index: any) => {
			if (index === 0)
				return {
					...controls,
					text: isState ? ControllerEnum.Resume : ControllerEnum.Pause,
					icon: isState ? IconPlay : IconPause,
				};
			return controls;
		});

		setControllerData(updateController);
	};

	/**
	 * Selected Channel
	 * @param device
	 * @returns
	 */
	const onSelectChannel = (deviceId: any) => {
		let selectedDevice;
		channelData?.map((channel: Channel) => {
			if (channel?.id === deviceId && channel?.selected === true) selectedDevice = true;
		});

		return selectedDevice;
	};

	/**
	 * First Selected Channel
	 * @param device
	 * @returns
	 */
	const onFirstSelectChannel = (deviceId: any) => {
		let firstSelectedDevice;
		channelData?.map((channel: Channel) => {
			if (channel?.id === deviceId && channel?.firstSelected === true) firstSelectedDevice = true;
		});

		return firstSelectedDevice;
	};

	/**
	 * Updating action text in the channel
	 * @param state
	 * @returns
	 */
	const checkActionText = (state: string) => {
		if (state === "paused") return globalAny.language.paused;
		if (state === "playing") return globalAny.language.playing;
		if (state === "syncing") return globalAny.language.syncing;
		if (state === "loading") return globalAny.language.loading;
		return defaultAction;
	};

	/**
	 * Checking the other devices
	 * @param screenName
	 * @returns
	 */
	const checkOtherDevices = (screenName: string) => {
		if (screenName === ProfileName.FrontScreen || screenName === ProfileName.LeftScreen || screenName === ProfileName.RightScreen) return false;
		return true;
	};

	/**
	 * Function for getting selected channel
	 */
	const getSelectedChannel = (data: any) => {
		const selected = data.filter((item: Channel) => item?.selected === true);
		return selected;
	};

	/**
	 * Use to get the "this Screen" header text
	 */
	const getHeaderText = (item: any) => {
		const deviceInfo = getDeviceInfo();
		if (item?.screenName === ProfileName.FrontScreen) {
			return deviceInfo.screenId === item?.screenId
				? `${globalAny.language.in_car} (${globalAny.language.this_screen})`
				: globalAny.language.in_car;
		}
		return item?.headerText;
	};

	/**
	 * Use for disabling controller
	 */
	const onControllerToggle = (disable: boolean) => {
		const selectedChannel = getSelectedChannel(channelData);

		const updateController = controllerData.map((controls: Controller) => {
			if (controls?.text === ControllerEnum.Cancel) return { ...controls, disabled: false };
			if (controls?.text === ControllerEnum.Cancel) return { ...controls, disabled: selectedChannel.length <= 1 };
			return { ...controls, disabled: disable };
		});

		setControllerData(updateController);
	};

	/**
	 * Handling the controller state for pause and resume
	 * @param state
	 */
	const updateController = (state: string) => {
		if (props.location === Pages.assignScreen) return;
		const isState = state === "Paused";
		const updateController = controllerData.map((controls: any, index: any) => {
			if (index === 0)
				return {
					...controls,
					text: isState ? ControllerEnum.Resume : ControllerEnum.Pause,
					icon: isState ? IconPlay : IconPause,
				};
			return controls;
		});

		setControllerData(updateController);
	};

	const getSelectedData = (device: any) => {
		return {
			deviceId: device.screenId,
			screenId: device.screenId,
			productId: device.productId,
			vamId: device.vamId,
			position: device.streamProgress,
			duration: device.duration,
			playSessionId: device.playSessionId,
			images: device.bgImage,
			title: device.title,
		};
	};

	const onPressAddScreens = () => {
		setSelectedTabs(3);
		//@ts-ignore
		navigation.navigate(routeNewSettings);
	};

	//#endregion

	//#region Function for Controller Command
	const onController = (control: any) => {
		switch (control) {
			case ControllerEnum.Pause:
				onPause();
				break;
			case ControllerEnum.Resume:
				onResume();
				break;
			case ControllerEnum.Stop:
				onStop();
				break;
			case ControllerEnum.Restart:
				onRestart();
				break;
			case ControllerEnum.Sync:
				onSync();
				break;
			case ControllerEnum.Cancel:
				onCancel();
				break;
			case ControllerEnum.Play:
				onPlay();
				break;
			default:
				break;
		}
	};

	const resetControllerVisibility = () =>
		setTimeout(() => {
			setHideController(true);
			setSyncRibbon(false);
			onCancel();
		}, 2000);

	/**
	 * Function for pause send command
	 */
	const onPause = () => {
		if (!mqttSender) return;
		const selectedChannel = getSelectedChannel(channelData);

		if (!mqttSender) return;

		selectedChannel.map((item: any) => {
			const watchGroup = JSON.parse(localStorage.getItem(AsyncStorageKeys.watchGroup) ?? "[]");
			const group = watchGroup.find((group: any) => group.playSessionId === item.playSessionId);

			if (group?.devices) {
				group?.devices
					.filter((device: any) => device.screenId !== getDeviceInfo().screenId)
					.map((device: any) => {
						mqttSender.mediaPauseCommand({ deviceId: device.deviceId, screenId: device.deviceId });
					});
				return;
			}

			const mqttRequest = { deviceId: item.screenId, screenId: item.screenId };
			mqttSender.mediaPauseCommand(mqttRequest);
		});

		updateController("Paused");
		setControllerText(globalAny.language.paused_success);
		setHideController(false);
		setSyncRibbon(true);
		resetControllerVisibility();
	};

	/**
	 * Function for resume send command
	 */
	const onResume = () => {
		const selectedChannel = getSelectedChannel(channelData);

		if (!mqttSender) return;

		selectedChannel.map((item: any) => {
			const watchGroup = JSON.parse(localStorage.getItem(AsyncStorageKeys.watchGroup) ?? "[]");
			const group = watchGroup.find((group: any) => group.playSessionId === item.playSessionId);

			if (group?.devices) {
				group?.devices
					.filter((device: any) => device.screenId !== getDeviceInfo().screenId)
					.map((device: any) => {
						mqttSender.mediaPlayCommand({ deviceId: device.deviceId, screenId: device.deviceId });
					});
				return;
			}

			const mqttRequest = { deviceId: item.screenId, screenId: item.screenId };
			mqttSender.mediaPlayCommand(mqttRequest);
		});

		updateController("Playing");
		setControllerText(globalAny.language.resume_success);
		setHideController(false);
		setSyncRibbon(true);
		resetControllerVisibility();
	};

	/**
	 * Function for restart send command
	 */
	const onRestart = () => {
		const selectedChannel = getSelectedChannel(channelData);

		if (!mqttSender) return;

		selectedChannel.map((item: Channel) => {
			const watchGroup = JSON.parse(localStorage.getItem(AsyncStorageKeys.watchGroup) ?? "[]");
			const group = watchGroup.find((group: any) => group.playSessionId === item.playSessionId);

			if (group.devices) {
				group.devices
					.filter((device: any) => device.screenId !== getDeviceInfo().screenId)
					.map((device: any) => {
						const mqttRequest: MqttPlayContent = {
							contentId: item.vamId ? item.vamId : item.productId,
							created: new Date().getTime(),
							deviceId: device.screenId,
							duration: 0,
							screenId: device.screenId,
							state: "playing",
							position: 0,
							playSessionId: item.playSessionId,
							vamId: item.vamId ?? 0,
						};
						mqttSender.mediaSyncCommand(mqttRequest);
					});

				return;
			}

			const mqttRequest: MqttPlayContent = {
				contentId: item.vamId ? item.vamId : item.productId,
				created: new Date().getTime(),
				deviceId: item.screenId,
				duration: 0,
				screenId: item.screenId,
				state: "playing",
				position: 0,
				playSessionId: item.playSessionId,
				vamId: item.vamId ?? 0,
			};
			mqttSender.mediaSyncCommand(mqttRequest);
		});

		setControllerText(globalAny.language.restart_success);
		setHideController(false);
		setSyncRibbon(true);
		resetControllerVisibility();
	};

	/**
	 * Function for restart send command
	 */
	const onStop = () => {
		const selectedChannel = getSelectedChannel(channelData);

		if (!mqttSender) return;

		selectedChannel.map((item: any) => {
			const watchGroup = JSON.parse(localStorage.getItem(AsyncStorageKeys.watchGroup) ?? "[]");
			const group = watchGroup.find((group: any) => group.playSessionId === item.playSessionId);

			if (group?.devices?.length) {
				group.devices
					.filter((device: any) => device.screenId !== getDeviceInfo().screenId)
					.map((device: any) => {
						mqttSender.mediaStopCommand({ deviceId: device.deviceId, screenId: device.deviceId });
					});
				return;
			}

			const mqttRequest = { deviceId: item.screenId, screenId: item.screenId };
			mqttSender.mediaStopCommand(mqttRequest);
		});

		onControllerToggle(true);
		setControllerText(globalAny.language.stop_success);
		setHideController(false);
		setSyncRibbon(true);
		resetControllerVisibility();
	};

	/**
	 * Function for play send command
	 */
	const onPlay = async () => {
		const selectedChannel = getSelectedChannel(channelData);
		const progress: any = await getProgressVideo(parentProductId);
		if (selectedChannel.length > 1) {
			const [firstChannel] = getSelectedChannel(channelData);
			onSendSyncCommand({
				deviceId: firstChannel.screenId,
				screenId: firstChannel.screenId,
				productId: parentProductId,
				playSessionId: uuidv4(),
				streamProgress: progress.streamProgress,
				vamId: vamId ?? 0,
				title: props?.title,
				images: props?.bgImage,
			});
			return;
		}

		const devices = selectedChannel.map((item: any) => {
			return { deviceId: item.screenId, screenId: item.screenId };
		});
		const content = {
			contentId: parentProductId,
			playSessionId: "", // replace to uuid
			position: progress.streamProgress,
			vamId: vamId ?? 0,
			title: props?.title,
			images: props?.bgImage,
		};

		if (!mqttSender) return;

		selectedChannel.map((item: any) => {
			const masterDevice = { deviceId: item.screenId, screenId: item.screenId };
			const mqttRequest = generatePlayContent(content, devices, masterDevice);
			vamId ? mqttSender.playVamCommand(mqttRequest) : mqttSender.playMovieCommand(mqttRequest);
		});

		setControllerText(globalAny.language.play_success);
		setHideController(false);
		setSyncRibbon(true);
		resetControllerVisibility();
	};

	const onSync = () => {
		setSync(true);
		setHideController(false);
		setSyncRibbon(true);
	};

	const onSendSyncCommand = (channel: any) => {
		const selectedChannel = getSelectedChannel(channelData);

		const content = {
			contentId: channel.productId,
			playSessionId: channel.playSessionId, // replace to uuid
			position: channel?.streamProgress ? channel?.streamProgress : channel.position,
			vamId: channel.vamId ?? 0,
			title: channel.title,
			images: channel.images,
		};

		if (!mqttSender) return;
		const masterDevice = { deviceId: channel.screenId, screenId: channel.screenId };

		if (!selectedChannel.find((device: any) => device.screenId === masterDevice.screenId)) {
			selectedChannel.push(masterDevice);
		}

		const devices = selectedChannel
			.map((item: any) => {
				return {
					deviceId: item.screenId,
					screenId: item.screenId,
				};
			})
			.sort(sortDevices);

		let mqttRequest = generatePlayContent(content, devices, masterDevice);

		devices.map((device: any) => {
			mqttRequest = { ...mqttRequest, deviceId: device.deviceId, screenId: device.screenId };

			vamId ? mqttSender.playVamCommand(mqttRequest) : mqttSender.playMovieCommand(mqttRequest);
		});

		setControllerText(globalAny.language.sync_success);
		setHideController(false);
		setSyncRibbon(true);
		resetControllerVisibility();
		setSync(false);
		setTimeout(() => onCancel(), 2000);
	};

	/**
	 * Function for cancel all the selected screen
	 */
	const onCancel = () => {
		setSync(false);

		setChannelData((prevChannelData) =>
			prevChannelData.map((channel: Channel) => {
				if (channel.selected === true) return { ...channel, selected: false };
				return channel;
			})
		);

		setControllerText(props.controllerText);
		setHideController(false);
		setSyncRibbon(false);
		setShowSpinner(false);
	};

	const onHandleControllerDisabling = (channel: any, checkSelected: any, productId: any, controlData: any) => {
		if (props.location !== Pages.assignScreen) {
			const checkSelectedProductId = channel.filter((val: any) => val.productId && val.selected === true);

			if (
				(checkSelected.length >= 1 && checkSelectedProductId.length >= 1) ||
				(checkSelected.length === 1 && productId && checkSelectedProductId.length === 1)
			) {
				controlData = controlData.map((controls: Controller) => {
					if (controls?.text !== ControllerEnum.Sync) return { ...controls, disabled: false };
					return controls;
				});
			} else {
				controlData = controlData.map((controls: Controller) => {
					if (controls?.text !== ControllerEnum.Cancel) return { ...controls, disabled: true };
					return controls;
				});
			}

			return controlData;
		}
		return controlData;
	};

	/**
	 * Handling the selected channel
	 * @param deviceId
	 * @returns
	 */
	const setSelectedChannel = (deviceId: any) => {
		let newChannelData: any = channelData.map((channel: Channel) => {
			if (channel?.id === deviceId && channel?.selected === false) return { ...channel, selected: true };
			if (channel?.id === deviceId && channel?.selected === true) return { ...channel, selected: false };
			return channel;
		});
		const selected = getSelectedChannel(newChannelData);
		if (selected.length === 1) {
			newChannelData = newChannelData.map((channel: Channel) => {
				if (channel?.id === deviceId && channel?.selected === true) {
					return { ...channel, firstSelected: true };
				}
				return channel;
			});
		}

		return newChannelData;
	};

	/**
	 * Use for sorting channels
	 * @param channel
	 * @returns
	 */
	const sortChannel = (channel: any) => {
		return channel.sort((a: any, b: any) => a?.headerText?.localeCompare(b?.headerText));
	};

	/**
	 * Use for sorting channels
	 * @param channel
	 * @returns
	 */
	const sortChannelById = (channel: any) => {
		return channel.sort((a: any, b: any) => a?.id?.localeCompare(b?.id));
	};

	/**
	 * Disabling sync button
	 * @param checkSelected
	 * @param productId
	 * @returns
	 */
	const updateSyncButton = (checkSelected: any, productId: any) => {
		const hasProduct = checkSelected.filter((channel: any) => channel.productId);
		const updateController = controllerData.map((controls: Controller) => {
			if (controls?.text === ControllerEnum.Sync && controls?.disabled === true && checkSelected.length >= 2 && hasProduct)
				return { ...controls, disabled: false };
			if (controls?.text === ControllerEnum.Sync && controls?.disabled === true && checkSelected.length >= 2 && !hasProduct && productId)
				return { ...controls, disabled: false };
			if (controls?.text === ControllerEnum.Sync && controls?.disabled === false && checkSelected.length <= 1)
				return { ...controls, disabled: true };
			return controls;
		});

		return updateController;
	};

	const onChannel = (channel: any) => {
		if (sync) {
			channel.productId && onSendSyncCommand(channel);
			channel.vamId && onSendSyncCommand(channel);
		}

		if (!sync) {
			setHideController(true);
			setSyncRibbon(false);
			setSync(false);
			setShowSpinner(false);
			const channelData = setSelectedChannel(channel.deviceId);

			if (channelData.length !== 0) {
				setChannelData(channelData);
			}

			const checkSelected = getSelectedChannel(channelData);
			let controllerData = updateSyncButton(checkSelected, channel.productId);
			controllerData = onHandleControllerDisabling(channelData, checkSelected, channel.productId, controllerData);
			updateControllerState(channelData, controllerData);

			if (checkSelected.length === 0) {
				setControllerText(props.controllerText);
				setHideController(false);
				setSyncRibbon(false);
			}
		}
	};

	const translateControllerText = (text: any) => {
		switch (text) {
			case "Play":
				return globalAny.language.play;
			case "Pause":
				return globalAny.language.pause;
			case "Resume":
				return globalAny.language.resume;
			case "Restart":
				return globalAny.language.restart;
			case "Sync":
				return globalAny.language.sync;
			case "Cancel":
				return globalAny.language.cancel;
			case "Stop":
				return globalAny.language.stop;
		}
	};
	//#endregion

	//#region useEffect
	useEffect(() => {
		if (!mqttDevices.length) {
			return;
		}

		setChannelData(convertToChannel(mqttDevices));
		setShowController(true);

		if (mqttDevices.length > 0) {
			setDelay(true);
		}

		return () => {
			setChannelData([]);
		};
	}, [mqttDevices]);
	//#endregion

	const renderChannel = ({ item }: any) => (
		// @ts-ignores
		<StyledCard key={`channel-${item.id}`}>
			<ScreenManagerChannelButtons
				key={`channel-button-${item.id}`}
				headerText={getHeaderText(item)}
				actionText={item.actionText}
				title={item.title}
				bgImage={item.bgImage}
				reserveImage={item.reserveImage}
				onPress={() => onChannel(getSelectedData(item))}
				link={item.link}
				selected={item.selected}
				indicator={item.loading}
				progressPercent={streamProgress(item.streamProgress ?? 0, item?.duration ?? 1)}
				groupSync={item.groupSync}
			/>
		</StyledCard>
	);

	const renderCancelButton = () => {
		// @ts-ignores
		return (
			<StyledCancelButton redirectTo={redirectTo}>
				<TouchableButton
					key={"Cancel"}
					componentTheme={ComponentThemeType.VinFast}
					type={ComponentTypeEnum.Secondary}
					onPress={() => onController(globalAny.language.cancel)}
					disabled={false}
				>
					{/* @ts-ignores */}
					<StyledImage>
						{/* @ts-ignores */}
						<StyledButtonText>{globalAny.language.cancel}</StyledButtonText>
					</StyledImage>
				</TouchableButton>
			</StyledCancelButton>
		);
	};

	const renderAddScreenButton = () => {
		return (
			// @ts-ignores
			<StyledAddScreenContainer>
				<TouchableButton
					componentTheme={ComponentThemeType.VinFast}
					type={ComponentTypeEnum.Primary}
					onPress={() => onPressAddScreens()}
					style={{ marginRight: spacing_m_vw }}
				>
					{/* @ts-ignores */}
					<StyledButtonText>{globalAny.language.add_screen}</StyledButtonText>
				</TouchableButton>
			</StyledAddScreenContainer>
		);
	};

	const renderController = ({ text, disabled, icon }: any) => (
		// @ts-ignores
		<StyledController key={`controller-${text}`} location={location}>
			{text ? (
				<TouchableButton
					componentTheme={ComponentThemeType.VinFast}
					type={text != ControllerEnum.Cancel ? ComponentTypeEnum.Primary : ComponentTypeEnum.Secondary}
					onPress={() => onController(text)}
					style={{ marginRight: spacing_m_vw }}
					disabled={disabled}
				>
					{/* @ts-ignores */}
					<StyledImage style={{ marginLeft: -10 }}>
						{icon ? <Image source={{ uri: icon }} width={32} height={32}></Image> : <></>}
						{/* @ts-ignores */}
						<StyledButtonText>{translateControllerText(text)}</StyledButtonText>
					</StyledImage>
				</TouchableButton>
			) : (
				<></>
			)}
			{!text ? <StyledEmptySpace /> : <></>}
		</StyledController>
	);

	const renderInitialRibbon = () => {
		return (
			// @ts-ignores
			<StyledTextContainer wheelPosition={wheelPosition} location={location} isSync={syncRibbon || props.location === Pages.assignScreen}>
				{/* @ts-ignores */}
				<StyledText wheelPosition={wheelPosition}>
					{showSpinner ? <ActivityIndicator size={"large"} color={"white"} /> : <></>}
					{wheelPosition !== "right" ? (
						<>
							{/* @ts-ignores */}
							<StyledControllerText direction={redirectTo === direction.player}>
								{controllerText || props.controllerText}
							</StyledControllerText>
							{props.location !== Pages.assignScreen && !syncRibbon && redirectTo !== direction.player ? (
								renderAddScreenButton()
							) : (
								<></>
							)}
						</>
					) : (
						<>
							{/* @ts-ignores */}
							{props.location !== Pages.assignScreen && !syncRibbon ? renderAddScreenButton() : <></>}
							{/* @ts-ignores */}
							<StyledRightTextContainer assignScreen={location} syncRibbon={syncRibbon}>
								{/* @ts-ignores */}
								<StyledControllerText>{controllerText || props.controllerText}</StyledControllerText>
							</StyledRightTextContainer>
						</>
					)}
				</StyledText>
			</StyledTextContainer>
		);
	};

	const renderSyncRibbon = () => {
		return (
			// @ts-ignores
			<StyledSync wheelPosition={wheelPosition}>
				{/* @ts-ignores */}
				<StyledCancelContainer>
					<TouchableButton
						componentTheme={ComponentThemeType.VinFast}
						type={ComponentTypeEnum.Secondary}
						onPress={() => onCancel()}
						style={wheelPosition !== "right" ? { marginRight: spacing_m_vw } : { marginLeft: spacing_m_vw }}
					>
						{/* @ts-ignores */}
						<StyledButtonText>{globalAny.language.cancel}</StyledButtonText>
					</TouchableButton>
				</StyledCancelContainer>
				{/* @ts-ignores */}
				<StyledSyncBody>
					{/* @ts-ignores */}
					<StyledControllerText>{syncBody}</StyledControllerText>
				</StyledSyncBody>
			</StyledSync>
		);
	};

	const renderControllerRibbon = () => {
		return (
			<>
				{/* @ts-ignores  */}
				<StyledControllerContainer>
					{/* @ts-ignores  */}
					{wheelPosition !== "right" ? (
						<>
							<StyledControllerButton location={location}>
								{controllerData.map((item) => {
									return renderController(item);
								})}
							</StyledControllerButton>
							{props.location !== Pages.assignScreen ? renderCancelButton() : <></>}
						</>
					) : (
						// @ts-ignores
						<>
							{props.location !== Pages.assignScreen ? renderCancelButton() : <></>}
							<StyledControllerButton location={location}>
								{controllerData.map((item) => {
									return renderController(item);
								})}
							</StyledControllerButton>
						</>
					)}
				</StyledControllerContainer>
			</>
		);
	};

	const reducedChannelData = useMemo(
		() =>
			channelData.reduce<ModelMappedScreensDisplay>(
				(init: ModelMappedScreensDisplay, curr: Channel) => {
					const updatedScreen =
						deviceInfo.screenName === curr?.screenName
							? { ...curr, headerText: `${getTranslatedDeviceName(curr.headerText)} (${globalAny.language.this_screen})` }
							:  { ...curr, headerText: `${getTranslatedDeviceName(curr.headerText)}` };			
					if (curr?.screenName === ProfileName.FrontScreen || curr?.screenName === ProfileName.PassengerScreen) {
						init.frontScreen = [...init.frontScreen, updatedScreen];
					} else if (curr?.screenName === ProfileName.RearScreen) {
						init.frontScreen = [...init.frontScreen, updatedScreen];
					} else if (curr?.otherDevices === true) {
						init.otherScreens = [...init.otherScreens, updatedScreen];
					} else {
						init.carScreens = [...init.carScreens, updatedScreen];
					}
					return init;
				},
				{ frontScreen: [], carScreens: [], otherScreens: [] }
			),
		[channelData]
	);
	//#endregion

	return (
		//@ts-ignores
		<StyledParentContainer>
			{/* @ts-ignores  */}
			<StyledMainContainer addMarginLeft={props.addMarginLeft}>
				<ScrollView style={{ zIndex: -1 }}>
					{channelData.length === 0 ? (
						// @ts-ignores
						<StyledLoading addMarginLeft={props.addMarginLeft}>
							<ImgProgressBar />
						</StyledLoading>
					) : (
						<></>
					)}

					<ScreenManagerContainer>
						{channelData ? (
							<FlatList
								data={sortChannelById(reducedChannelData.frontScreen)}
								extraData={reducedChannelData.frontScreen}
								renderItem={renderChannel}
								keyExtractor={(item: any) => `channel-list-${item.id}`}
								contentContainerStyle={{
									flex: 1,
									alignSelf: "flex-start",
									width: reducedChannelData.frontScreen.length > 1 ? "50%" : "100%",
									borderWidth: 0,
								}}
								numColumns={2}
								showsVerticalScrollIndicator={false}
								showsHorizontalScrollIndicator={false}
							/>
						) : (
							<></>
						)}
					</ScreenManagerContainer>

					<ScreenManagerContainer>
						{channelData ? (
							<FlatList
								data={sortChannel(reducedChannelData.carScreens)}
								extraData={reducedChannelData.carScreens}
								renderItem={renderChannel}
								keyExtractor={(item: any) => `channel-list-${item.id}`}
								contentContainerStyle={{
									flex: 1,
									alignSelf: "flex-start",
									width: reducedChannelData.carScreens.length > 1 ? "50%" : "100%",
									borderWidth: 0,
								}}
								numColumns={2}
								showsVerticalScrollIndicator={false}
								showsHorizontalScrollIndicator={false}
							/>
						) : (
							<></>
						)}
					</ScreenManagerContainer>

					{channelData.length >= 1 ? (
						// @ts-ignores
						<StyledMargin>
							<Separator type={ComponentTypeEnum.Primary}></Separator>
						</StyledMargin>
					) : (
						<></>
					)}

					{channelData.length >= 1 && reducedChannelData.otherScreens.length === 0 && delay ? (
						//@ts-ignores
						<StyledNoDevices>
							{/* @ts-ignores */}
							<StyledOtherDevicesHeader>{globalAny.language.other_devices}</StyledOtherDevicesHeader>
							{/* @ts-ignores */}
							<StyledOtherDevicesBody>{globalAny.language.no_other_screens_available}</StyledOtherDevicesBody>
						</StyledNoDevices>
					) : (
						<></>
					)}

					{!delay ? (
						//@ts-ignores
						<StyledOtherDevicesContainer>
							{/* @ts-ignores */}
							<StyledOtherDevicesLoader width={526} height={24} marginBottomLarge={true}>
								<ImgProgressBar />
							</StyledOtherDevicesLoader>
							{/* @ts-ignores */}
							<StyledOtherDevicesLoader width={526} height={16}>
								<ImgProgressBar />
							</StyledOtherDevicesLoader>
							{/* @ts-ignores */}
							<StyledOtherDevicesLoader width={172} height={16}>
								<ImgProgressBar />
							</StyledOtherDevicesLoader>
						</StyledOtherDevicesContainer>
					) : (
						<></>
					)}

					{reducedChannelData.otherScreens.length > 0 ? (
						//  @ts-ignores
						<StyledWithDevices>
							<ScreenManagerContainer>
								{channelData ? (
									<FlatList
										data={sortChannel(reducedChannelData.otherScreens)}
										extraData={reducedChannelData.otherScreens}
										renderItem={renderChannel}
										keyExtractor={(item: any) => `controller-list-${item.id}`}
										contentContainerStyle={{ flex: 1, alignSelf: "flex-start", width: "50%", borderWidth: 0 }}
										numColumns={2}
										showsVerticalScrollIndicator={false}
										showsHorizontalScrollIndicator={false}
									/>
								) : (
									<></>
								)}
							</ScreenManagerContainer>
						</StyledWithDevices>
					) : (
						<></>
					)}
				</ScrollView>
			</StyledMainContainer>

			{showController ? (
				// @ts-ignores
				<ControllerContainer>
					{/* @ts-ignores */}
					<StyledContainer wheelPosition={wheelPosition} addMarginLeft={props.addMarginLeft}>
						{!hideController && !sync ? renderInitialRibbon() : <></>}
						{sync ? renderSyncRibbon() : <></>}
						{hideController && !sync ? renderControllerRibbon() : <></>}
					</StyledContainer>
				</ControllerContainer>
			) : (
				<></>
			)}
		</StyledParentContainer>
	);
};

export default ScreenManager;
