import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { Themes } from "../Types";
import { DARK } from "../utils/themes/DARK";
import { Dimensions } from "react-native";

const windowDimensions = Dimensions.get('window');
const screenDimensions = Dimensions.get('screen');

const useGenericContentStore = create(
	devtools((set: any) => ({
		selectedTabs: 0,
		setSelectedTabs: (selectedTabs: any) => set({ selectedTabs: selectedTabs }),
		redeemReload: false,
		setRedeemReload: (redeemReload: any) => set({ redeemReload: redeemReload }),
		navigateEvent: "",
		setNavigateEvent: (navigateEvent: any) => set({ navigateEvent: navigateEvent }),
		streamPosition: 0,
		setStreamPosition: (streamPosition: any) => set({ streamPosition: streamPosition }),
		streamCurrentProgress: 0,
		setStreamProgress: (streamCurrentProgress: any) => set({ streamCurrentProgress: streamCurrentProgress }),
		movieTitle: "",
		setMovieTitle: (movieTitle: any) => set({ movieTitle: movieTitle }),
		vamDetails: 0,
		setVamDetails: (vamDetails: any) => set({ vamDetails: vamDetails }),
		isLogin: false,
		setIsLogin: (isLogin: boolean) => set({ isLogin: isLogin }),
		currentResumeProgress: 0,
		setCurrentResumeProgress: (currentResumeProgress: any) => set({ currentResumeProgress: currentResumeProgress }),
		aggregatePageId: { id: null, location: null },
		setAggregatePageId: (id: any, location: string) => set({aggregatePageId:{ id: id, location: location }}),
		aggregateTitle: "",
		setAggregateTitle: (aggregateTitle: any) => set({ aggregateTitle: aggregateTitle }),
		isSuccess: { result: false, isRented: false },
		setIsSuccess: (isSuccess: boolean, isRented: boolean) => set({ isSuccess: { result: isSuccess, isRented: isRented } }),
		mistakeCount: 0,
		setMistakeCount: (mistakeCount: number) => set({ mistakeCount: mistakeCount }),
		currentTheme: Themes.DARK,
		setCurrentTheme: (currentTheme: any) => set({ currentTheme: currentTheme }),
		themeManager: DARK,
		setThemeManager: (themeManager: any) => set({ themeManager: themeManager }),
		isEnabledFloatingButton: false,
		setIsEnabledFloatingButton: (isEnabledFloatingButton: boolean) => set({ isEnabledFloatingButton: isEnabledFloatingButton }),
		numberOfScreens: 3,
		setNumberOfScreens: (numberOfScreens: number) => set({ numberOfScreens: numberOfScreens }),
		channelData: [],
		setChannelData: (channelData: any) => set({ channelData: channelData }),
		loginEmail: "",
		setLoginEmail: (loginEmail: string) => set({ loginEmail: loginEmail }),
		isHide: true,
		setIsHide: (isHide: boolean) => set({ isHide: isHide }),
		isDebugVisible: false,
		setIsDebugVisible: (isDebugVisible: boolean) => set({isDebugVisible: isDebugVisible}),
		dimensions: {
			window: windowDimensions,
			screen: screenDimensions,
		},
		setDimensions: (dimensions: any) => set({dimensions:dimensions}),
		uniqueId: "",
		setUniqueId: (uniqueId: string) => set({uniqueId: uniqueId}),
		showHWIDError: false,
		setShowHWIDError: (showHWIDError: boolean) => set({showHWIDError: showHWIDError}),
		isRenderTDP: false,
		setIsRenderTDP: (isRenderTDP: boolean) => set({isRenderTDP: isRenderTDP}),
		isRenderRedeem: false,
		setIsRenderRedeem: (isRenderRedeem: boolean) => set({isRenderRedeem: isRenderRedeem})
	}))
);

export default useGenericContentStore;
