import { globalAny } from "../utils/Utils";
import { getHeaders } from "../utils/endpoint/header";
import { fetchWrapper } from "../utils/endpoint/fetch";
import ENV_CONFIG from "../config/ENV_CONFIG";

export const Code_OK = 10000;
export const TEMPORARY_PASSWORD_OK = 10003;
export const Code_SESSION_ERROR = 20008;
export const PRODUCT_REDEEMED = 10006;
export const PRODUCT_ALREADY_REDEEMED = 10002;
export const INVALID_PASSWORD = 40003;
export const INVALID_PROFILENAME_OR_PASSWORD = 40005;
export const INVALID_PROFILENAME = 40137;
export const ACCOUNT_EXIST = 40016;
export const INVALID_SHORTCODE = 40317;
export const DEVICE_NOT_ENTITLE = 40222;
export const HWID_EXIST = 40325;

export enum TRANSACTION_TYPES {
	EST = 1,
	SVOD = 2,
	TVOD = 3,
	FVOD = 4,
}

export enum STREAM_TYPES {
	SMOOTH = 1,
	DASH = 2,
	HLS = 3,
}

export enum SUBTITLE_TYPES {
	VTT = "vtt",
	SRT = "srt",
}

export enum PRODUCT_TRACK_FILE_TYPES {
	SUBTITLE_VTT = 3,
	FORCED_VTT = 9,
	DASH = 11,
	THUMBNAIL = 27,
	PRE_ROLL = 25,
	POST_ROLL = 26,
	SKIP_LESS_PRE_ROLL = 28,
}

export const getPresignedUrl = async () => {
	const URL = `${ENV_CONFIG.API_URL}/ps-drm/v1/mqtt`;
	let headers = {
		Session: globalAny.SESSIONKEY,
	};

	const result = await fetchWrapper(URL, { headers: headers });
	return result;
};

export const fetchWrapperWithSessionHeader = async (url: string) => {
	const result = await fetchWrapper(url, { headers: getHeaders(false) });
};
