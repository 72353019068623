import { Text, StyleSheet, View } from "react-native";
import { black, spacing_l, spacing_m, white } from "../../StyleHelpers";
import styled, { css } from "styled-components/native";
import TouchableButton from "../../components/TouchableButton";
import ComponentThemeType from "../../models/ComponentTheme";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import PlayIcon from "../../assets/Icons/Play.png";
import AssignIcon from "../../assets/Icons/ScreenManager.png";
import Image from "../../components/Image/Image";
import TouchableOpacity from "../../components/TouchableOpacity/TouchableOpacity";
import GenresComponent from "../../components/GenresComponent";
import { useEffect, useState } from "react";
import ImgProgressBar from "../../components/Loaders/ImgProgressBar";
import { globalAny } from "../../utils/Utils";
import useGenericContentStore from "../../store/genericContent.store";
import { CarActivity } from "../../Types";
import useDrivingStore from "../../store/useDriving.store";
import useToggleStore from "../../store/useToggle.store";

const StyledContainer = styled.View`
	width: 58%;
	height: 100vh;
	padding: 0 0 0 122px;
	z-index: -1;
`;

const StyledButtonLoader = styled.View`
	height: 72px;
	border-radius: 16px;
	overflow: hidden;
	margin-bottom: ${spacing_m};
`;

const StyledButtonContainer = styled.View<any>`
	width: 81%;
	${(props) =>
		!props.isButtonVisible
			? css`
					display: block;
			  `
			: css`
					display: none;
			  `}
`;

const StyledButton = styled.View`
	flex-direction: row;
	align-items: center;
	width: 100%;
`;

interface Buttons {
	text: string;
	icon: string;
	theme: ComponentThemeType;
	type: ComponentTypeEnum;
	onPress: () => void;
}

const ViewDetails = (props: any) => {
	const isToggleMQTT = useToggleStore((state: any) => state.isToggleMQTT);
	const buttonDataDefault = [
		{
			text: props.streamProgress ? globalAny.language.resume : globalAny.language.play,
			icon: PlayIcon,
			theme: ComponentThemeType.VinFast,
			type: ComponentTypeEnum.Primary,
			onPress: props.onPlay,
			redeem: true,
		},
		{
			text: globalAny.language.redeem_for_one,
			icon: "",
			theme: ComponentThemeType.VinFast,
			type: ComponentTypeEnum.Tertiary,
			onPress: props.onRedeemMovie,
			redeem: false,
			isShow: true,
		},
		{
			text: globalAny.language.purchase,
			icon: "",
			theme: ComponentThemeType.VinFast,
			type: ComponentTypeEnum.Primary,
			onPress: props.onPurchase,
			redeem: false,
			isShow: true,
		},
		{
			text: props.rentText,
			icon: "",
			options: "rent",
			theme: ComponentThemeType.VinFast,
			type: ComponentTypeEnum.Primary,
			onPress: props.onPurchase,
			redeem: false,
		},
		{
			text: props.purchaseText,
			options: "purchase",
			icon: "",
			theme: ComponentThemeType.VinFast,
			type: ComponentTypeEnum.Primary,
			onPress: props.onPurchase,
			redeem: false,
		},
		isToggleMQTT && {
			text: globalAny.language.assign_to_screens,
			icon: AssignIcon,
			theme: ComponentThemeType.VinFast,
			type: ComponentTypeEnum.Secondary,
			onPress: props.onAssignScreen,
			redeem: true,
		},
		props.isTrailer && {
			text: globalAny.language.trailer,
			icon: "",
			theme: ComponentThemeType.VinFast,
			type: ComponentTypeEnum.Secondary,
			onPress: props.onTrailer,
			redeem: false,
		},
	];

	const [isButtonVisible, setIsButtonVisible] = useState(false);
	const [data, setData] = useState({});
	const carActivity = useDrivingStore((state: any) => state.carActivity);
	const setSelectedTabs = useGenericContentStore((state: any) => state.setSelectedTabs);
	const isRenderTDP = useGenericContentStore((state: any) => state.isRenderTDP);
	const isRenderRedeem = useGenericContentStore((state: any) => state.isRenderRedeem);

	const goToDetails = () => {
		setSelectedTabs(1);
	};

	useEffect(() => {
		setIsButtonVisible(false);
	}, [isRenderTDP]);

	useEffect(() => {
		setIsButtonVisible(false);
		if (props.isLoadingButton) {
			setIsButtonVisible(false);
			return;
		}
		setIsButtonVisible(true);
	}, [props.isLoadingButton]);

	useEffect(() => {
		let buttonData = buttonDataDefault
			.filter((val) => val.redeem === props.redeemed || val.text === globalAny.language.trailer || val.isShow)
			.map((item) => {
				if (item.text === globalAny.language.redeem_for_one && !props.availabilityRedeem) return;
				if (item.text === globalAny.language.redeem_for_one && props.isRedeem) return;
				if (item.text === globalAny.language.purchase && props.isPurchase) return;
				if (availabilityChecking(item)) return;
				return { ...item };
			});

		setData(buttonData);
	}, [props.redeemed, props?.streamProgress, props?.isTrailer, isRenderRedeem]);

	const availabilityChecking = (item: any) => {
		if ((item.options === "rent" && !props.availabilityRent) || (item.options === "rent" && props.availabilityPurchase && props.availabilityRent))
			return true;

		if (
			(item.options === "purchase" && !props.availabilityPurchase) ||
			(item.options === "purchase" && props.availabilityPurchase && props.availabilityRent)
		)
			return true;

		if (item.text === globalAny.language.purchase && (!props.availabilityRent || !props.availabilityPurchase) && !props.isShowOptions)
			return true;
	};

	const renderButtons = (item: Buttons, id: number) => {
		return (
			<TouchableButton
				key={`tdp-button-${id}`}
				addMarginRight={true}
				fontColor={item.type === ComponentTypeEnum.Tertiary ? black : white}
				componentTheme={item.theme}
				type={item.type}
				isStreamProgress={props.streamProgress && item.text === globalAny.language.resume ? props.streamProgress : 0}
				onPress={item.onPress}
				style={{ marginBottom: spacing_m }}
				disabled={item.text !== globalAny.language.assign_to_screens && carActivity === CarActivity.INMOTION}
			>
				{/* @ts-ignore */}
				<StyledButton>
					{item.icon ? <Image source={{ uri: item.icon }} width={56} height={56} /> : <></>}
					{item.text}
				</StyledButton>
			</TouchableButton>
		);
	};

	return (
		//@ts-ignore
		<StyledContainer>
			{props.metadata && (
				<View>
					<GenresComponent
						rating={props?.rating}
						genres={props?.genres}
						expiryDate={props?.expiryDate}
						isRent={props.streamProgress !== null && props.streamProgress === undefined ? false : props?.isRent}
					/>
					<Text style={[styles.metadata, { fontSize: 18, width: "80%", marginBottom: spacing_l }]}>{props?.ratingReason}</Text>

					<TouchableOpacity onPress={goToDetails}>
						<Text
							style={[styles.metadata, { fontSize: 25, width: "80%", marginBottom: spacing_l }]}
							numberOfLines={3}
							ellipsizeMode="tail"
						>
							{props?.synopsis}
						</Text>
					</TouchableOpacity>
				</View>
			)}

			{/* @ts-ignore */}
			<StyledButtonContainer isButtonVisible={isButtonVisible}>
				{/* @ts-ignore */}
				<StyledButtonLoader>
					<ImgProgressBar />
				</StyledButtonLoader>

				{/* @ts-ignore */}
				<StyledButtonLoader>
					<ImgProgressBar />
				</StyledButtonLoader>

				{/* @ts-ignore */}
				<StyledButtonLoader>
					<ImgProgressBar />
				</StyledButtonLoader>
			</StyledButtonContainer>

			{isButtonVisible && (
				//  @ts-ignore
				<StyledButtonContainer>
					{/* @ts-ignore */}
					{data.map((item: any, index: number) => (item ? renderButtons({ ...item }, index) : <></>))}
				</StyledButtonContainer>
			)}
		</StyledContainer>
	);
};

export default ViewDetails;

const styles = StyleSheet.create({
	metadata: {
		color: "white",
	},
	button: {
		fontSize: 25,
	},
});
