import { useEffect, useState } from "react";
import useMqttStore from "../../store/useMqtt.store";
import { AppLogger } from "../../utils/AppLogger";
import { iocContainer } from "../../inversify.config";
import MqttConductorService from "../../services/mqtt/MqttConductorService";
import MqttConnectionStatus from "../../models/MqttConnectionStatus";
import MqttDeviceManager from "../../services/mqtt/MqttDeviceManager";
import IMqttClient from "../../models/IMqttClient";
import IMqttDeviceBroadcast from "../../models/IMqttDeviceBroadcast";
import MqttSenderService from "../../services/mqtt/MqttSenderService";
import MqttReceiverService from "../../services/mqtt/MqttReceiverService";
import { endProgress, getDateTimePlusMinute, getDeviceInfo, globalAny, inWatchGroup, isMaster, sortDevices } from "../../utils/Utils";
import useLoginStore from "../../store/useLogin.store";
import { TRANSACTION_TYPES, getPresignedUrl } from "../../networking/networking";
import { AsyncStorageKeys, ProfileName, ProgressEvents, PurchaseTransactionType } from "../../Types";
import { getMetadata, getOwnedStatus } from "../../services/metadataService";
import { getVamMetadata } from "../../services/vamService";
import { getTitleDetailsImages } from "../../services/apiMoviesContentService";
import Toast from "../Toast";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import { getConcurrency, postConcurrency } from "../../services/videoService";
import { v4 as uuidv4 } from "uuid";
import { setProgressVideo } from "../../services/progressVideoService";
import useToggleStore from "../../store/useToggle.store";

//@ts-ignore
window.mqttDevices = [];
//@ts-ignore
window.mqttGroupSync = [];
//@ts-ignore
const MQTTWrapper = ({ children }) => {
	const isLoggedIn = useLoginStore((state: any) => state.isLoggedIn);
	const presignedUrl = useMqttStore((state: any) => state.presignedUrl);
	const mqttReceiver: MqttReceiverService = useMqttStore((state: any) => state.mqttReceiver);
	const mqttSender: MqttSenderService = useMqttStore((state: any) => state.mqttSender);
	const mqttTopic = useMqttStore((state: any) => state.mqttTopic);
	const mqttConductor = useMqttStore((state: any) => state.mqttConductor);
	const setMqttConnectionStatus = useMqttStore((state: any) => state.setMqttConnectionStatus);
	const setMqttTopic = useMqttStore((state: any) => state.setMqttTopic);
	const setMqttReceiver = useMqttStore((state: any) => state.setMqttReceiver);
	const setMqttSender = useMqttStore((state: any) => state.setMqttSender);
	const setPresignedUrl = useMqttStore((state: any) => state.setPresignedUrl);
	const setMqttConductor = useMqttStore((state: any) => state.setMqttConductor);
	const setMqttDevices = useMqttStore((state: any) => state.setMqttDevices);
	const deviceInfo = useMqttStore((state: any) => state.deviceInfo);
	const setMqttPlayContent = useMqttStore((state: any) => state.setMqttPlayContent);
	const setPlaySessionId = useMqttStore((state: any) => state.setPlaySessionId);
	const isToggleMQTT = useToggleStore((state: any) => state.isToggleMQTT);
	const [visible, setVisible] = useState(false);

	/**
	 * Remove device with lastHeartbeatReceivedTime
	 */
	const validateDevicesHeartbeat = () => {
		const deviceInfo = getDeviceInfo();
		//@ts-ignore
		window.mqttDevices = window.mqttDevices.filter(
			(device: any) => device.lastHeartbeatReceivedTime >= new Date() || deviceInfo.deviceId === device.deviceId
		);
		//@ts-ignore
		setMqttDevices(window.mqttDevices.slice(0));
	};

	const broadcastSync = (mqttPlayContent: any, currentDevice: any) => {
		//@ts-ignore
		if (isMaster(mqttPlayContent, currentDevice) && inWatchGroup(mqttPlayContent, currentDevice) && !window.isBuffering) {
			mqttSender.mediaSyncCommand(mqttPlayContent);
		}
	};

	/**
	 * Send broadcast
	 * if device has mqttPlayContent, this will send media-position
	 * if device is idle, this will send broadcast-device
	 */
	const broadcastHeartbeat = () => {
		const deviceInfo = getDeviceInfo();
		const trackingId = localStorage.getItem(AsyncStorageKeys.trackingId);
		//@ts-ignore
		if (!mqttSender || !deviceInfo.deviceName || window.holdHeartbeat || window.ignoreCommands) return;

		//@ts-ignore
		const mqttPlayContent = window.mqttPlayContent;

		if (mqttPlayContent) {
			broadcastSync(mqttPlayContent, deviceInfo);
			mqttSender.broadcastPositionCommand(mqttPlayContent);

			if (mqttPlayContent.state === "playing" && trackingId !== "undefined" && trackingId) {
				//@ts-ignore
				if (window.preRollPlaying || window.isDubCardPlaying) return;
				
				setProgressVideo(
					parseInt(mqttPlayContent.contentId),
					parseInt(mqttPlayContent.position) ?? 0,
					1,
					parseInt(mqttPlayContent.duration) ?? 0,
					ProgressEvents.update,
					trackingId,
					// @ts-ignore
					window.transactionType ?? 1
				);
				localStorage.setItem(AsyncStorageKeys.streamProgress, mqttPlayContent.position);
			}
			return;
		}
		AppLogger.log("[MQTT] Sent heartbeat");

		validateDevicesHeartbeat();
		mqttSender.broadcastPositionCommand({
			contentId: -1,
			created: new Date().getTime(),
			deviceId: "",
			duration: 0,
			playSessionId: "",
			position: 0,
			screenId: "",
			state: "stopped",
			vamId: 0,
		});
	};

	const broadcastPing = () => {
		const currentDevice = getDeviceInfo();
		//@ts-ignore
		const mqttPlayContent = window.mqttPlayContent;

		if (!mqttPlayContent) return;

		if (!isMaster(mqttPlayContent, currentDevice) && inWatchGroup(mqttPlayContent, currentDevice)) {
			//@ts-ignore
			window.pingSent = Date.now();
			mqttSender.sendPingCommand(new Date().getTime());
		}
	};

	/**
	 * Initialize mqttSender and mqttReceiver Instance
	 * @param mqttClient
	 * @param topic
	 */
	const onConnectedToMqtt = (mqttClient: IMqttClient, topic: string) => {
		const deviceInfo = getDeviceInfo();
		const deviceManagerInstance = new MqttDeviceManager(mqttClient, deviceInfo, topic);
		const mqttSenderInstance = new MqttSenderService(mqttClient, deviceInfo);
		const mqttReceiverInstance = new MqttReceiverService(mqttClient, deviceInfo, topic, deviceManagerInstance, true);
		setMqttSender(mqttSenderInstance);
		setMqttReceiver(mqttReceiverInstance);
		setMqttTopic(topic);

		setMqttConnectionStatus(MqttConnectionStatus.Connected);
	};

	/**
	 * Initialize mqttConductor
	 */
	const initializeConductor = () => {
		if (mqttSender) mqttSender.removeScreen();

		AppLogger.log("initializing conductor service...");
		const conductorServiceInstance: MqttConductorService = iocContainer.get<MqttConductorService>("MqttConductorService");
		setMqttConductor(conductorServiceInstance);
	};

	const isMHU = (device: any) => device.screenName === ProfileName.FrontScreen;

	/**
	 * Add if device is not existing on the list. if existing, replace the device from the list.
	 * @param newDevice incoming device from device-broadcast
	 * @returns
	 */
	const addDevice = (newDevice: IMqttDeviceBroadcast) => {
		if (newDevice.deviceName) {
			//@ts-ignore
			const devices = window.mqttDevices;
			const deviceIndex = devices.findIndex((device: IMqttDeviceBroadcast) => newDevice.screenId === device.screenId);
			const isExist = deviceIndex > -1;
			const deviceToAdd = isExist
				? { ...devices[deviceIndex], lastHeartbeatReceivedTime: getDateTimePlusMinute(), isMHU: isMHU(devices[deviceIndex]) }
				: { ...newDevice, lastHeartbeatReceivedTime: getDateTimePlusMinute(), isMHU: isMHU(newDevice) };

			if (isExist) {
				devices[deviceIndex] = deviceToAdd;
				//@ts-ignore
				window.mqttDevices = devices.slice(0);
				setMqttDevices(devices.slice(0));
				return;
			}

			devices.push(deviceToAdd);
			//@ts-ignore
			window.mqttDevices = devices.slice(0);
			setMqttDevices(devices.slice(0));
			AppLogger.log("[MQTT] Current Devices: ", devices);
		}
	};

	/**
	 * Add if device is not existing on the list. if existing, replace the device from the list.
	 * @param newDevice incoming device from device-broadcast
	 * @returns
	 */
	const removeDevice = (deviceToRemove: IMqttDeviceBroadcast) => {
		if (deviceToRemove.deviceName) {
			//@ts-ignore
			const devices = window.mqttDevices;
			const filteredDevices = devices.filter((device: IMqttDeviceBroadcast) => deviceToRemove.screenId !== device.screenId);
			//@ts-ignore
			window.mqttDevices = filteredDevices.slice(0);
			setMqttDevices(filteredDevices.slice(0));
			AppLogger.log("[MQTT] Device removed: ", deviceToRemove);
		}
	};

	/**
	 * Handle device-broadcast event was triggered / received
	 * if `message.sendOnly` is false and `message.screenId` is same with current screenId, send discover-account command.
	 * if `message.screenId` is not same with current screenId, add new device to list of devices.
	 * @param message - Device Broadcast message
	 * @returns
	 */
	const onDeviceBroadcast = (message: any) => {
		AppLogger.log("[MQTT] Received device-broadcast: ", message);
		const deviceInfo = getDeviceInfo();

		if (!message.screenName) return;

		if (!message.sendOnly && message.screenId === deviceInfo.screenId) {
			addDevice(message);
			mqttSender.discoverAccountDevices();
		}

		addDevice(message);
	};

	/**
	 * Handle discover-account event was triggered / received
	 * if `message.screenId` is not the same with current screenId, send back a device-broadcast command.
	 * @param message Discover Account message
	 */
	const onDiscoverAccount = (message: any) => {
		const deviceInfo = getDeviceInfo();
		//@ts-ignore
		const mqttPlayContent = window.mqttPlayContent;

		//@ts-ignore
		if (message.screenId !== deviceInfo.screenId && deviceInfo.screenId) {
			AppLogger.log("[MQTT] Received discover-account: ", message);

			if (mqttPlayContent) {
				mqttSender.broadcastDevice();
				mqttSender.broadcastPositionCommand(mqttPlayContent);
				return;
			}

			mqttSender.setDeviceBroadcastAsSendOnly();
			mqttSender.broadcastDevice();
		}
	};

	/**
	 * Handle remove screen event was triggered / received
	 * @param message - Device Broadcast message
	 * @returns
	 */
	const onRemoveScreen = (message: any) => {
		AppLogger.log("[MQTT] Received disconnect: ", message);
		removeDevice(message);
	};

	/**
	 * Update watch group when receives media-position with playSessionId
	 * @param message
	 */
	const updateWatchGroup = (message: any) => {
		AppLogger.log("updateWatchGroup", message);
		const currentDevice = { deviceId: message.deviceId, screenId: message.screenId };
		//@ts-ignore
		if (!message.body?.playSessionId || message?.body.contentId === -1 || !currentDevice?.deviceId) return;
		// @ts-ignore
		const watchGroup = JSON.parse(localStorage.getItem(AsyncStorageKeys.watchGroup)) ?? [];
		const group = {
			created: new Date().getTime(),
			playSessionId: message.body.playSessionId,
			devices: [currentDevice],
		};

		if (watchGroup?.length) {
			//Remove from previous watchGroup
			const previousWatchGroupIndex = watchGroup.findIndex(
				(group: any) =>
					group.playSessionId !== message.body.playSessionId && group.devices.find((device: any) => device.screenId === message.screenId)
			);
			if (previousWatchGroupIndex > -1) {
				watchGroup[previousWatchGroupIndex].devices = watchGroup[previousWatchGroupIndex].devices.filter(
					(device: any) => device.screenId !== message.screenId
				);
			}

			//Update to New Group
			const watchGroupIndex = watchGroup.findIndex((group: any) => group.playSessionId === message.body.playSessionId);

			if (watchGroupIndex > -1) {
				const devices = watchGroup[watchGroupIndex].devices;
				if (!devices.find((device: any) => device.screenId === message.deviceId)) {
					devices.push(currentDevice);
					watchGroup[watchGroupIndex].devices = devices.sort(sortDevices);
					localStorage.setItem(AsyncStorageKeys.watchGroup, JSON.stringify(watchGroup.filter((group: any) => group.devices.length)));
				}
				return;
			}

			watchGroup.push(group);
			localStorage.setItem(AsyncStorageKeys.watchGroup, JSON.stringify(watchGroup.filter((group: any) => group.devices.length)));
			return;
		}
		localStorage.setItem(AsyncStorageKeys.watchGroup, JSON.stringify([group]));
	};

	const checkConcurrencyLimit = async (productId: any) => {
		const ownedStatus: any = await getOwnedStatus(productId, [TRANSACTION_TYPES.EST, TRANSACTION_TYPES.SVOD, TRANSACTION_TYPES.TVOD]);
		const hasRented = ownedStatus?.find((val: any) => val.transactionType === TRANSACTION_TYPES.TVOD && val.owned === true);
		const hasPurchased = ownedStatus?.find((val: any) => val.transactionType !== TRANSACTION_TYPES.TVOD && val.owned === true);
		const ownedTransaction = ownedStatus?.find((val: any) => val.owned === true);
		const isRent = !hasPurchased && hasRented;
		const streamLimit: any = await getConcurrency(ownedTransaction.transactionType);
		const isPlayer = window.location.pathname.includes("player");
		//@ts-ignore
		const mqttPlayContent = window.mqttPlayContent;
		if (mqttPlayContent) {
			//@ts-ignore
			endProgress(window.transactionType ?? 1);
		}

		if (!streamLimit.globalStreams || (streamLimit?.streamsAvailable === 0 && !isPlayer)) {
			setVisible(true);
			return true;
		}

		if (!isPlayer) {
			await postConcurrency(isRent ? PurchaseTransactionType.Rent : PurchaseTransactionType.Purchase);
		}

		return false;
	};

	const playDevices = async (message: any) => {
		AppLogger.log("[MQTT] Received play-sync: ", message);
		const decodedBody = message.body;
		const deviceInfo = getDeviceInfo();
		const productId = decodedBody.contentId;

		if (decodedBody.deviceId === deviceInfo.deviceId && decodedBody.devices.find((device: any) => device.screenId === deviceInfo.screenId)) {
			if (await checkConcurrencyLimit(productId)) return;

			//@ts-ignore
			clearInterval(window.syncMediaPositionTimer);
			//@ts-ignore
			window.syncMediaPositionTimer = null;
			AppLogger.log("SYNCTEST", {
				body: decodedBody.playSessionId,
				current: localStorage.getItem(AsyncStorageKeys.playSessionId),
			});
			if (decodedBody.playSessionId !== localStorage.getItem(AsyncStorageKeys.playSessionId)) {
				setPlaySessionId(decodedBody.playSessionId);
				localStorage.setItem(AsyncStorageKeys.playSessionId, decodedBody.playSessionId);
				setMqttPlayContent(decodedBody);
				//@ts-ignore
				window.mqttPlayContent = decodedBody;
			}

			if (decodedBody.devices.length === 1) {
				const playSessionId = uuidv4();
				setPlaySessionId(playSessionId);
				localStorage.setItem(AsyncStorageKeys.playSessionId, playSessionId);
			}

			if (decodedBody.devices.length > 1) {
				setPlaySessionId(decodedBody.playSessionId);
				localStorage.setItem(AsyncStorageKeys.playSessionId, decodedBody.playSessionId);
				updateWatchGroup(message);
			}
		}
	};

	const onPlayMovie = (message: any) => {
		AppLogger.log("[MQTT] Received play-movie: ", message);

		playDevices(message);
	};

	const onPlayVam = (message: any) => {
		AppLogger.log("[MQTT] Received play-vam: ", message);

		playDevices(message);
	};

	const saveMovieDetails = async (movieDetails: any) => {
		const contentId = movieDetails?.contentId;
		const vamId = movieDetails?.vamId;
		const movieData: any = localStorage.getItem(`moviedetails_${contentId}`);
		const vamData: any = localStorage.getItem(`vamdetails_${vamId}`);
		if (!vamId && (movieData || !contentId)) return;
		if (vamId && (vamData || !contentId)) return;

		const metadata: any = await getMetadata(contentId);
		const image: any = await getTitleDetailsImages(contentId);
		const [packshot] = metadata.images;
		let vamMetadata: any;
		let title: any = metadata.title;
		let imageUrl: any = `${image?.tbg?.imageUrl ?? packshot.url}?width=300&disablewebp=false`;
		localStorage.setItem(
			`moviedetails_${contentId}`,
			JSON.stringify({
				title,
				image: imageUrl,
			})
		);

		if (vamId) {
			vamMetadata = await getVamMetadata(contentId);
			const [vam] = vamMetadata.vam.filter((vam: any) => vam.id === vamId);
			title = metadata?.title ? `${metadata?.title} - ${vam?.title}` : vam?.title;
			imageUrl = vam?.imageUrl;

			localStorage.setItem(
				`vamdetails_${vamId}`,
				JSON.stringify({
					title,
					image: imageUrl,
				})
			);
		}
	};

	/**
	 * Remove from watch group when receives media-position with contentId === -1
	 * @param playSessionId
	 */
	const removeFromWatchGroup = (screenId: any) => {
		// @ts-ignore
		let watchGroups = JSON.parse(localStorage.getItem(AsyncStorageKeys.watchGroup)) ?? [];

		if (watchGroups.length) {
			const watchGroupIndex = watchGroups.findIndex(
				(group: any) => group?.devices?.findIndex((device: any) => device.screenId === screenId) > -1
			);

			if (watchGroupIndex > -1) {
				// Get Group
				let group = watchGroups[watchGroupIndex];
				// Remove device from watch group
				let devices = group.devices;
				devices = devices.filter((device: any) => device.screenId !== screenId);

				// If watch group is contain only one device remove the watch group from watchgroup list
				if (devices.length === 1 || !devices.length) {
					watchGroups = watchGroups.filter((group: any) => group.playSessionId !== watchGroups[watchGroupIndex].playSessionId);
				} else {
					group.devices = devices;
					watchGroups[watchGroupIndex] = group;
				}

				// If watchgroups is empty remove the local data
				watchGroups.length
					? localStorage.setItem(AsyncStorageKeys.watchGroup, JSON.stringify(watchGroups.filter((group: any) => group.devices.length > 1)))
					: localStorage.removeItem(AsyncStorageKeys.watchGroup);
			}
		}
	};

	const isSameDevice = (message: any) => message.deviceId === getDeviceInfo().deviceId;

	/**
	 * Handle media position event listener
	 * @param message
	 */
	const onMediaPositionListener = (message: any) => {
		AppLogger.log("[MQTT] Received media-position: ", message);
		//@ts-ignore
		const devices = window.mqttDevices;
		const decodedBody = message.body;
		const deviceIndex = devices.findIndex((device: IMqttDeviceBroadcast) => message.screenId === device.screenId);
		const isExist = deviceIndex > -1;

		//@ts-ignore
		if (!message.deviceName) return;

		if (isExist && (decodedBody.state === "stopped" || decodedBody.contentId === -1)) {
			removeFromWatchGroup(message.screenId);
			delete devices[deviceIndex].movieDetails;
			devices[deviceIndex] = {
				...devices[deviceIndex],
				deviceName: message.deviceName,
				screenName: message.screenName,
				lastHeartbeatReceivedTime: getDateTimePlusMinute(),
			};
			//@ts-ignore
			window.mqttDevices = devices.slice(0);
			setMqttDevices(devices.slice(0));

			if (isSameDevice(message)) {
				//@ts-ignore
				window.mqttPlayContent = null;
				setMqttPlayContent(null);
			}
			return;
		}

		if (isExist && decodedBody.state !== "stopped") {
			saveMovieDetails(decodedBody);
			updateWatchGroup(message);
			devices[deviceIndex] = { ...devices[deviceIndex], movieDetails: message, lastHeartbeatReceivedTime: getDateTimePlusMinute() };
			//@ts-ignore
			window.mqttDevices = devices.slice(0);
			setMqttDevices(devices.slice(0));
			return;
		}

		devices.push({
			deviceId: message.deviceId,
			screenId: message.screenId,
			deviceName: message.deviceName,
			screenName: message.screenName,
			sendOnly: message.sendOnly,
			movieDetails: message,
		});

		if (decodedBody.contentId !== -1) {
			saveMovieDetails(decodedBody);
			updateWatchGroup(message);
		}

		//@ts-ignore
		window.mqttDevices = devices.slice(0);
		setMqttDevices(devices.slice(0));
	};

	const onDismissSnackBar = () => setVisible(false);
	const onPressToast = () => setVisible(false);

	useEffect(() => {
		initializeConductor();

		//@ts-ignore
		if (window.heartBeatTimer) {
			//@ts-ignore
			clearInterval(window.heartBeatTimer);
		}

		return () => {};
	}, []);

	useEffect(() => {
		(async () => {
			if (!globalAny.profileName || !mqttConductor) return;
			if (!presignedUrl?.host) {
				const presignedService = await getPresignedUrl();
				setPresignedUrl(presignedService);
			}
		})();
	}, [globalAny.profileName, isLoggedIn, mqttConductor]);

	useEffect(() => {
		if (!mqttConductor || !presignedUrl?.host) return;

		mqttConductor.onConnect(onConnectedToMqtt);
		mqttConductor.onDisconnect(() => {
			setMqttConnectionStatus(MqttConnectionStatus.Offline);
		});
		mqttConductor.connectToMqttBroker(presignedUrl);
	}, [mqttConductor, presignedUrl]);

	/**
	 * subscribe all MQTT event listeners
	 */
	useEffect(() => {
		if (!mqttReceiver || !mqttTopic) return;

		mqttReceiver.deleteAllEventListeners();

		if (isToggleMQTT) {
			mqttReceiver.onDeviceBroadcastListener(onDeviceBroadcast);
			mqttReceiver.addOnDiscoverAccountEvents(onDiscoverAccount);
			mqttReceiver.addOnRemoveScreenEvents(onRemoveScreen);
			mqttReceiver.addOnPlayMovieSendEvent(onPlayMovie);
			mqttReceiver.addOnVamSendEvent(onPlayVam);
			mqttReceiver.addOnMediaPositionListener(onMediaPositionListener);

			if (deviceInfo?.screenName) {
				setTimeout(() => {
					mqttSender.setDeviceBroadcast(deviceInfo);
					mqttSender.broadcastDevice();
				}, 1000);
			}
		}

		return () => mqttReceiver.deleteAllEventListeners();
	}, [mqttReceiver, mqttTopic]);

	useEffect(() => {
		//@ts-ignore
		clearInterval(window.heartBeatTimer);
		//@ts-ignore
		clearInterval(window.pingTimer);

		if (!mqttSender) return;

		//@ts-ignore
		window.heartBeatTimer = setInterval(() => broadcastHeartbeat(), 5000);
		//@ts-ignore
		window.pingTimer = setInterval(() => broadcastPing(), 5000);
	}, [mqttSender]);

	return (
		<>
			{children}
			<Toast
				visible={visible}
				text={globalAny.language.concurrent_limit}
				label={globalAny.language.ok}
				type={ComponentTypeEnum.Secondary}
				onDismissSnackBar={onDismissSnackBar}
				onPress={() => onPressToast()}
			/>
		</>
	);
};

export default MQTTWrapper;
